import { DateRange, DateRangeNames } from '@fv/client-types'
import { dates } from '@fv/models/core'

// eslint-disable-next-line @typescript-eslint/ban-types
export type DateRangeValue = DateRange | (string & {})
export type ParsedDateRange = {
  name: DateRange | 'custom'
  isCustom: boolean
  start?: Date
  end?: Date
}

export const parseDateRange = (
  value: string | null | undefined,
  defaultRange: DateRange = 'last-7-days',
): ParsedDateRange => {
  if (!value) {
    return {
      name: defaultRange,
      isCustom: false,
      start: undefined,
      end: undefined,
    }
  }

  const isCustom = !DateRangeNames.includes(value as DateRange)
  let start: Date | undefined = undefined
  let end: Date | undefined = undefined
  if (isCustom) {
    const parsed = dates.parseDateRange(value)
    start = parsed.start
    end = parsed.end
  }

  return {
    name: isCustom ? 'custom' : (value as DateRange),
    isCustom,
    start,
    end,
  }
}
